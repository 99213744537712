<div class="container">
  <div class="auth-card">
    <div class="auth-card-content">
      <img src="../../../assets/images/logo/ea-only.png" class="auth-card-logo" />
      <img src="../../../assets/images/logo/logo-text.png" class="auth-card-logo-text" />
      <slot name="content"></slot>
    </div>
    <div class="auth-card-side">
      <div class="auth-card-side-image"></div>
    </div>
  </div>
</div>
