
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import AuthTemplate from '../../atomic/templates/AuthTemplate/AuthTemplate.vue';

export default defineComponent({
  components: {
    'auth-template': AuthTemplate,
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
  },
});
